import { useState, useEffect } from "react";
import styles from "./bag-item.module.css";
import Image from "next/image";
import ImageButton from "../Buttons/ImageButton";
const { parse, basename } = require('path');

const BagItem = ({ item, removeProductFromCart, updateProductCount }) => {
  const [count, setCount] = useState(item.quantity || 1);
  const [availableStock, setAvailableStock] = useState(0);
  const availableProductQuantity = item?.productCount
    ? parseInt(item?.productCount)
    : Number.MAX_SAFE_INTEGER;
  const isProductCustomised = item.customAttributes.length > 0;

  const countEffect = () => {
    updateProductCount(item.id, count);
    if (item.size || item.colour) {
      const stock = findProductStockAndPrice(item.size, item.colour, item);
      setAvailableStock(stock.stockAvailable);
    } else {
      setAvailableStock(item.availableStock);
    }
  };
  useEffect(countEffect, [count]);

  if (item?.fields?.['Other photos']?.length) {
    localStorage.clear();
    window.location.reload();
  }

  const findProductStockAndPrice = (size, colour, product) => {
    if (!size) {
      size = '-';
    }
    if (!colour) {
      colour = '-';
    }
    const variantPrice = product.variantOptions;
    const variantObj = {};
    
    if (!variantPrice || (variantPrice && !variantPrice.length)) {
      return product.price;
    }

    variantPrice.forEach(variant => {
      let vSize = variant &&  variant.type === 'fit'
        ? variant?.name : '-';
      let vColor =  variant && variant.type === 'color'
        ? variant?.name : '-';

      if (!variantObj[vSize]) {
        variantObj[vSize] = {
          [vColor]: {
            price: variant?.price,
            stockAvailable: variant?.availableStock
          }
        };
      } else {
        variantObj[vSize][vColor] = {
          price: variant?.price,
          stockAvailable: variant?.availableStock
        };
      }
    });

    return variantObj[size][colour];
  }
  const urlPath =  (item?.productPhotos[0]) ? new URL(item?.productPhotos[0]).pathname : "";
  const extractedImageName = basename(parse(urlPath).name);
  console.log("extractedImageName " + extractedImageName)
  const newPhoto = `https://dm2buy-drop-resized-gga4c6azekgcgngp.z02.azurefd.net/dm2buy/${extractedImageName}.jpg`;
  const fallBackPhoto = item?.productPhotos[0];        


  return (
    <div className={styles.orderItem}>
      <div className={styles.productDetails}>
        <div className={styles.thumbnailContainer}>
          
          <Image
            src={newPhoto}
            unoptimized="true"
            height={60}
            width={60}
            alt="Order name"
            className={styles.orderThumbnail}
            onError={(e) => {
              // Handle image load error by setting a fallback URL
              e.target.src = fallBackPhoto;
              
            }}
          />

          {item.colour ? (
            <div
              className={styles.productColor}
              style={{ backgroundColor: item.colour }}
            ></div>
          ) : null}
        </div>
        <div className={styles.productName}>
          <span>{item.name}</span>
          {isProductCustomised && (
            <div className={styles.product_specs}>
              {item.customAttributes
                .map((ca, index) => (
                  <span key={index + 1}>
                    {ca.name} - {ca.value + ""}
                  </span>
                ))
                .reduce((prev, curr) => [prev, ", ", curr])}
            </div>
          )}
          <div className={styles.product_specs}>
            {
              item.size
                ? 'Variant - ' + item.size : ''
            }
          </div>
          {availableProductQuantity > 1 && !isProductCustomised && (
            <div className={styles.quantityControls}>
              <ImageButton
                type="raised"
                action={() => {
                  if (count < 2) return;
                  setCount(count - 1);
                }}
              >
                <Image src="/buttons/decrement@3x.png" width={9} height={2} />
              </ImageButton>
              <span>{count}</span>
              <ImageButton
                type="raised"
                action={() => {
                  if (count < availableStock) {
                    setCount(count + 1);
                  }
                }}
              >
                <Image src="/buttons/increment@3x.png" width={9} height={9} s />
              </ImageButton>
            </div>
          )}
        </div>
      </div>
      <div className={styles.details_right}>
        <div className={styles.productPrice}>
          {`${String.fromCharCode(0x20b9)}${count * item.price}`}
        </div>
        <img
          onClick={removeProductFromCart(item.id)}
          src="/invalid-name@2x.png"
        />
      </div>
    </div>
  );
};

export default BagItem;
